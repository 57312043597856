// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Include functions first
@import "bootstrap/scss/functions";

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Helpers
// @import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";


//
// Custom styles
//

//@import "icon-list";

:root {    

	--bs-gray:#869ab8;
	--bs-gray-dark:#384c74;
	--bs-gray-100:#f9fbfd;
	--bs-gray-200:#f1f4f8;
	--bs-gray-300:#d9e2ef;
	--bs-gray-400:#c6d3e6;
	--bs-gray-500:#abbcd5;
	--bs-gray-600:#869ab8;
	--bs-gray-700:#506690;
	--bs-gray-800:#384c74;
	--bs-gray-900:#1b2a4e;

    --bs-dark: #1b2a4e;
    --bs-dark-rgb: 27,42,78;

	--bs-light:#f9fbfd;
	--bs-light-rgb:249,251,253;


    --bs-font-sans-serif:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	--bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
	--bs-gradient:linear-gradient(180deg,hsla(0,0%,100%,.15),hsla(0,0%,100%,0));
	--bs-body-font-family:"HKGroteskPro",serif;
	--bs-body-font-size:1.0625rem;
	--bs-body-font-weight:400;
	--bs-body-line-height:1.6;
	--bs-body-color:#161c2d;
	--bs-body-color-rgb:22,28,45;
	--bs-body-bg:#fff;
	--bs-body-bg-rgb:255,255,255;
}



hr
{
	border:0;
	border-top:var(--bs-border-width) solid;
	color:var(--bs-border-color);
	margin:1rem 0;
	opacity:1
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6
{
	color:var(--bs-heading-color);
	font-weight:400;
	line-height:1;
	margin-bottom:.5rem;
	margin-top:0
}
.h1,h1
{
	font-size:2.3125rem
}
.h2,h2
{
	font-size:1.9375rem
}
.h3,h3
{
	font-size:1.3125rem
}
.h4,h4
{
	font-size:1.1875rem
}
.h5,h5
{
	font-size:1.0625rem
}
.h6,h6
{
	font-size:.75rem
}
	p
{
	margin-bottom:1rem;
	margin-top:0
}
	abbr[title]
{
	cursor:help;
	-webkit-text-decoration:underline dotted;
	text-decoration:underline dotted;
	-webkit-text-decoration-skip-ink:none;
	text-decoration-skip-ink:none
}
	address
{
	font-style:normal;
	line-height:inherit;
	margin-bottom:1rem
}
	ol,ul
{
	padding-left:2rem
}
	dl,ol,ul
{
	margin-bottom:1rem;
	margin-top:0
}
	ol ol,ol ul,ul ol,ul ul
{
	margin-bottom:0
}
	dt
{
	font-weight:600
}
	dd
{
	margin-bottom:.5rem;
	margin-left:0
}
	blockquote
{
	margin:0 0 1rem
}
	b,strong
{
	font-weight:700
}
.small,small
{
	font-size:88.2%
}
.mark,mark
{
	background-color:var(--bs-highlight-bg);
	padding:.1875em
}
	sub,sup
{
	font-size:.75em;
	line-height:0;
	position:relative;
	vertical-align:baseline
}
	sub
{
	bottom:-.25em
}
	sup
{
	top:-.5em
}
	a
{
	color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
	text-decoration:none
}
	a:hover
{
	--bs-link-color-rgb:var(--bs-link-hover-color-rgb);
	text-decoration:underline
}
	a:not([href]):not([class]),a:not([href]):not([class]):hover
{
	color:inherit;
	text-decoration:none
}
	code,kbd,pre,samp
{
	font-family:var(--bs-font-monospace);
	font-size:1em
}
	pre
{
	display:block;
	font-size:.8125rem;
	margin-bottom:1rem;
	margin-top:0;
	overflow:auto
}
	pre code
{
	color:inherit;
	font-size:inherit;
	word-break:normal
}
	code
{
	word-wrap:break-word;
	color:var(--bs-code-color);
	font-size:.8125rem
}
	a>code
{
	color:inherit
}
	kbd
{
	background-color:var(--bs-body-color);
	border-radius:.25rem;
	color:var(--bs-body-bg);
	font-size:.8125rem;
	padding:.1875rem .375rem
}
	kbd kbd
{
	font-size:1em;
	padding:0
}
	figure
{
	margin:0 0 1rem
}
	img,svg
{
	vertical-align:middle
}
	table
{
	border-collapse:collapse;
	caption-side:bottom
}
	caption
{
	color:var(--bs-secondary-color);
	padding-bottom:1.5rem;
	padding-top:1.5rem;
	text-align:left
}
	th
{
	text-align:inherit;
	text-align:-webkit-match-parent
}
	tbody,td,tfoot,th,thead,tr
{
	border:0 solid;
	border-color:inherit
}
	label
{
	display:inline-block
}
	button
{
	border-radius:0
}
	button:focus:not(:focus-visible)
{
	outline:0
}
	button,input,optgroup,select,textarea
{
	font-family:inherit;
	font-size:inherit;
	line-height:inherit;
	margin:0
}
	button,select
{
	text-transform:none
}
	[role=button]
{
	cursor:pointer
}
	select
{
	word-wrap:normal
}
	select:disabled
{
	opacity:1
}
	[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator
{
	display:none!important
}
	[type=button],[type=reset],[type=submit],button
{
	-webkit-appearance:button
}
	[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled)
{
	cursor:pointer
}
	::-moz-focus-inner
{
	border-style:none;
	padding:0
}
	textarea
{
	resize:vertical
}
	fieldset
{
	border:0;
	margin:0;
	min-width:0;
	padding:0
}
	legend
{
	float:left;
	font-size:1.5rem;
	line-height:inherit;
	margin-bottom:.5rem;
	padding:0;
	width:100%
}
	legend+*
{
	clear:left
}
	::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field
{
	padding:0
}
	::-webkit-inner-spin-button
{
	height:auto
}
	[type=search]
{
	-webkit-appearance:textfield;
	outline-offset:-2px
}
	::-webkit-search-decoration
{
	-webkit-appearance:none
}
	::-webkit-color-swatch-wrapper
{
	padding:0
}
	::-webkit-file-upload-button
{
	-webkit-appearance:button;
	font:inherit
}
	::file-selector-button
{
	-webkit-appearance:button;
	font:inherit
}
	output
{
	display:inline-block
}
	iframe
{
	border:0
}
	summary
{
	cursor:pointer;
	display:list-item
}
	progress
{
	vertical-align:baseline
}
	[hidden]
{
	display:none!important
}
.lead
{
	font-size:1.3125rem;
	font-weight:400
}
.display-1
{
	font-size:4.375rem;
	font-weight:400
}
.display-2
{
	font-size:3.8125rem;
	font-weight:400
}
.display-3
{
	font-size:3rem;
	font-weight:400
}
.display-4
{
	font-size:2.6875rem;
	font-weight:400
}
.list-inline,.list-unstyled
{
	list-style:none;
	padding-left:0
}
.list-inline-item
{
	display:inline-block
}
.list-inline-item:not(:last-child)
{
	margin-right:.5rem
}
.initialism
{
	font-size:88.2%;
	text-transform:uppercase
}
.blockquote
{
	font-size:1.25rem;
	margin-bottom:1rem
}
.blockquote>:last-child
{
	margin-bottom:0
}
.blockquote-footer
{
	color:var(--bs-gray-600);
	font-size:.75rem;
	margin-bottom:1rem;
	margin-top:-1rem
}
.blockquote-footer:before
{
	content:"— "
}
.img-fluid,.img-thumbnail
{
	height:auto;
	max-width:100%
}
.img-thumbnail
{
	background-color:var(--bs-body-bg);
	border:var(--bs-border-width) solid var(--bs-border-color);
	border-radius:var(--bs-border-radius);
	box-shadow:var(--bs-box-shadow-sm);
	padding:.25rem
}
.figure
{
	display:inline-block
}
.figure-img
{
	line-height:1;
	margin-bottom:.5rem
}
.figure-caption
{
	color:#869ab8;
	font-size:88.2%
}




body {
  overflow-x: clip;
}

@font-face
{
	font-family:HKGroteskPro;
	font-weight:400;
	src:url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2) format("woff2"),url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff) format("woff")
}
	@font-face
{
	font-family:HKGroteskPro;
	font-weight:600;
	src:url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2) format("woff2"),url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff) format("woff")
}
	@font-face
{
	font-family:HKGroteskPro;
	font-weight:700;
	src:url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff2) format("woff2"),url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff) format("woff")
}
	@font-face
{
	font-family:Feather;
	font-style:normal;
	font-weight:400;
	src:url(../fonts/Feather/Feather.ttf?sdxovp) format("truetype"),url(../fonts/Feather/Feather.woff?sdxovp) format("woff"),url(../fonts/Feather/Feather.svg?sdxovp#Feather) format("svg")
}
.h1,.h2,h1,h2
{
	margin-bottom:1rem
}
.h1,.h2,.h3,.h4,h1,h2,h3,h4
{
	letter-spacing:-.01em
}
.h1,h1
{
	line-height:1.3
}
	@media(max-width:991.98px)
{
.h1,h1
{
	font-size:1.875rem
}

}
.h2,h2
{
	line-height:1.35
}
	@media(max-width:991.98px)
{
.h2,h2
{
	font-size:1.5rem
}

}
.h3,h3
{
	line-height:1.4
}
	@media(max-width:991.98px)
{
.h3,h3
{
	font-size:1.125rem
}

}
.h4,h4
{
	line-height:1.45
}
	@media(max-width:991.98px)
{
.h4,h4
{
	font-size:1.0625rem
}

}
.h5,h5
{
	line-height:1.5
}
.h6,h6
{
	line-height:1.55
}
.h6.text-uppercase,h6.text-uppercase
{
	letter-spacing:.08em
}
.display-1,.display-2,.display-3,.display-4
{
	letter-spacing:-.02em
}
.display-1
{
	line-height:1.2
}
	@media(max-width:991.98px)
{
.display-1
{
	font-size:3.125rem
}

}
.display-2
{
	line-height:1.2
}
	@media(max-width:991.98px)
{
.display-2
{
	font-size:2.5rem
}

}
.display-3
{
	line-height:1.2
}
	@media(max-width:991.98px)
{
.display-3
{
	font-size:2.25rem
}

}
.display-4
{
	line-height:1.2
}
	@media(max-width:991.98px)
{
.display-4
{
	font-size:2rem
}
.lead
{
	font-size:1.1875rem
}

}
.blockquote-img
{
	max-height:2.5rem;
	max-width:100%;
	width:auto
}
.blockquote-footer
{
	margin-top:0
}
.blockquote-footer:before
{
	display:none
}
.list-social-icon
{
	height:auto;
	max-width:1.25rem
}
	[class*=hr-]
{
	margin-left:auto;
	margin-right:auto
}
.hr-sm
{
	width:60px
}
.hr-sm:not([size])
{
	height:calc(var(--bs-border-width)*2)
}
.hr-md
{
	width:100px
}
.hr-md:not([size])
{
	height:calc(var(--bs-border-width)*2)
}

[class^=container] {
    position: relative;
    z-index: 1;
}

[class^=col] {
    position: relative
}

@media (min-width: 768px){
    .mw-md-150 { max-width: 150%!important; }
    .pt-md-11 { padding-top: 6rem!important; }
}

@media (min-width: 992px){
    .mw-lg-130 { max-width: 130%!important; }
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1040px; }
}

.row {
    --bs-gutter-x: 2.5rem;
    --bs-gutter-y: 0;
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0,0,0,0)!important;
    border: 0!important;
    height: 1px!important;
    margin: -1px!important;
    overflow: hidden!important;
    padding: 0!important;
    white-space: nowrap!important;
    width: 1px!important
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption),.visually-hidden:not(caption) {
    position: absolute!important
}

.btn {
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-padding-y: 0.8125rem;
    
    cursor: pointer;
    display: inline-block;
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle
}

.modal .btn-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 1000
}



@import "styles/form.scss";

@import "styles/margin.scss";

@import "styles/padding.scss";

@import "styles/gap.scss";

@import "styles/icon.scss";

@import "styles/shape.scss";

@import "styles/background.scss";

@import "styles/card.scss";

@import "styles/text.scss";

@import "styles/font.scss";

@import "styles/shadow.scss";